@charset "utf-8";

//@font-face {
// font-family: 'Inter';
// src: url('/fonts/Inter-VariableFont_slnt,wght.ttf') format('ttf'); // pattern: /directoryName/file.extension
// font-style: normal;
// }

@font-face {
    font-family: 'Rubik'; //Rubik, Helvetica, Arial, sans-serif ... 'Rubik', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
    font-display: swap;
    //src: url('/fonts/Rubik-Regular.ttf') format('ttf'), url('/fonts/Rubik-Regular.woff') format('woff');
    //font-style: normal;
}

// COLORS
$primary: #0c92a0;
$info: #0c92a0;
$success: #23d160;
$warning: #ffdd57;
$danger: #ff3860;
$light: #f4f5f7;
$dark: #1a202c;

// TEXT
$text: #1a202c;
$link: #0c92a0;
$subtext-family: 'Rubik', sans-serif;
$body-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Helvetica', 'Arial', sans-serif;
$title-family: false;
$button-family: false;

//NAVBAR
$navbar-item-hover-background-color: transparent;
$navbar-item-active-background-color: transparent;
$navbar-tab-active-background-color: transparent;
$navbar-item-hover-color: #0c92a0;
$navbar-box-shadow-color: #f4f5f7;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3rem 1.5rem;
$section-padding-smallP: 3.8rem 1.5rem;
$section-padding-medium: 7rem 1rem;
$section-padding-large: 10rem 1.5rem;

// SPACING HELPERS (.mt-3, .mx-auto, .p-5, etc)
$spacing-shortcuts: (
    'margin': 'm',
    'padding': 'p'
);
$spacing-directions: (
    'top': 't',
    'right': 'r',
    'bottom': 'b',
    'left': 'l'
);
$spacing-horizontal: 'x';
$spacing-vertical: 'y';
$spacing-values: (
    '0': 0,
    '1': 0.25rem,
    '2': 0.5rem,
    '3': 1rem,
    '4': 1.5rem,
    '5': 3rem,
    'auto': auto
);

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O

// IMPORT BULMA
@import '~bulma/bulma.sass';

// IMPORT FONT AWESOME
@import url('https://use.fontawesome.com/releases/v5.10.1/css/all.css');

body {
    // Fix Bulma bug where columns cause
    // horizontal scroll on mobile.
    // https://github.com/jgthms/bulma/issues/449
    overflow: hidden;
    ::-moz-selection {
        /* Code for Firefox */
        color: #0a0a0a;
        background: #79ff96;
    }

    ::selection {
        color: #0a0a0a;
        background: #79ff96;
    }
}

#__next {
    // Full height of screen and flex so that we can push footer
    // to the bottom even if content is shorter than screen
    min-height: 100vh;
    background-color: #f4f5f7;
    scroll-behavior: smooth;
    //min-height: calc(100vh - 354px);
    display: flex;
    flex-direction: column;
    // Push footer to the bottom of the screen
    > .footer {
        margin-top: auto;
    }
    // Prevent child elements from shrinking when content
    // is taller than the screen (quirk of having flex parent)
    > * {
        flex-shrink: 0;
        background-color: #f4f5f7;
    }
}

.catFont {
    font-family: 'Rubik', sans-serif;
    color: #4a545e;
    border-radius: 8px;
}

.title,
h1,
h2,
h3,
h4 {
    color: #04184a !important;
}

.isFFF {
    color: #fff !important;
}

.overflowRadiusImage {
    overflow: hidden;
    border-radius: 4px;
}

#chatra:not(.chatra--expanded) {
    visibility: hidden !important;
    opacity: 0 !important;
    pointer-events: none;
    transition: none;
}

/* *{
  box-sizing: border-box
}
.columns,
.columns:last-child {
  margin: 0
} */

.groupScrollArea::-webkit-scrollbar {
    opacity: 0;
    width: 12px;
    border-radius: 8px;
}

.groupScrollArea::-webkit-scrollbar-thumb {
    opacity: 0;
    transition: 0.3s;
}

.groupScrollArea:hover::-webkit-scrollbar-thumb {
    opacity: 1;
    //background-color: #a3a4a430;
    width: 8px;
    background-color: #00000010;
    opacity: 0.5;
    border: 4px solid transparent;
    background-clip: content-box;
    margin-top: 2px;
    border-radius: 10px;
}

.groupScrollArea::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: 2px;
}

.groupScrollArea::-webkit-scrollbar-thumn:hover {
    background-color: #50565e;
}

/*.groupScrollAreaHover::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    margin-top: 2px;
}

.groupScrollAreaHover::-webkit-scrollbar {
    background-color: transparent;
    width: 15px;
}

 .groupScrollAreaHover::-webkit-scrollbar-thumb {
    background-color: #00000010;
    opacity: 0.5;
    border: 6px solid transparent;
    background-clip: content-box;
    border-radius: 10px;
}

.groupScrollAreaHover::-webkit-scrollbar-thumb:hover {
    background-color: #c0c5cc;
}

.groupScrollAreaDark::-webkit-scrollbar {
    opacity: 0;
}

.groupScrollAreaDarkHover::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    margin-top: 2px;
}

.groupScrollAreaDarkHover::-webkit-scrollbar {
    background-color: transparent;
    width: 15px;
}

.groupScrollAreaDarkHover::-webkit-scrollbar-thumb {
    background-color: #3c4146;
    opacity: 0.5;
    border: 6px solid transparent;
    background-clip: content-box;
    border-radius: 10px;
}

.groupScrollAreaDarkHover::-webkit-scrollbar-thumb:hover {
    background-color: #50565e;
} */
