@media screen and (min-width: 768px) {
  .blogCardStyle {
    padding: 3.75rem 3.75rem 5.75rem 3.75rem;
  }
}

@media screen and (max-width: 768px) {
  .blogCardStyle {
    padding: 2rem;
  }
}
