.sideBar {
    //background-color: transparent !important;
}

@media screen and (min-width: 768px) {
    .sideBar {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    }

        @media screen and (min-width: 768px) {
            .sideBarMobile {
                padding-left: 6.75rem;
                z-index: 999999 !important;
            }
            }
            @media screen and (max-width: 767px) {
                .sideBarMobile {
                    padding-left: 0.75rem;
                    z-index: 999999 !important;
                }
                }