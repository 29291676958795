.FooterComponent {
  &__container {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      flex: none;
      justify-content: center;
      width: 100%;
      margin-bottom: 24px;
    }

    .brand {
      img {
        display: block;
        height: 32px;
      }
    }

    .social {
      align-items: flex-end;
    }

    .socialRow {
      display: flex;
    }

    .social,
    .links {
      a {
        color: inherit;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.54px;
        font-family: "Rubik", sans-serif;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    // Tablet and up
    @media screen and (min-width: 769px) {
      > div {
        flex: 50%;
      }

      .socialRow {
        display: contents;
      }

      .left {
        justify-content: flex-start;
      }

      .right {
        justify-content: flex-end;
      }

      // Move links to end (bottom right)
      // Swaps position with social
      .links {
        order: 1;
      }
    }
  }
}
