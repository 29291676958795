.tabStyle {
    padding: 4px 42px;
    font-weight: 600;
    border: none;
    outline: none;
    height: 28px;
    margin-left: -6px,
  }
.tabStyle:first-child {
    border-radius: 6px 0px 0px 6px;
    margin-left: 0px,
  }
  .tabStyle:last-child {
    border-radius: 0px 6px 6px 0px;
  }

