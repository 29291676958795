.navbar-item,
.navbar-link:hover {
  background-color: transparent !important;
}

@media screen and (max-width: 1023px) {
  .priceSwitchContainer {
    padding: 1rem 2.5rem 3rem;
  }
  .flexAndBlock {
    display: flex;
  }
  .prodquote {
    margin-top: 8rem;
  }
}

@media screen and (max-width: 1023px) {
  .switchText {
    letter-spacing: 0.24px;
    font-size: 14px;
    //margin-right: 0px !important;
  }
  .prodquote {
    font-size: 1.5rem !important;
    margin-top: 0rem !important;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-centered {
    flex-direction: column;
    align-items: center;
  }

  .column.is-4 {
    width: auto;
    max-width: initial;
    margin: 1rem 0;
  }
}