.HeroCTA {
  //font-size: 1.5rem !important;
  //font-weight: 500 !important;
}

@media screen and (min-width: 768px) {
  .HeroCTA {
    font-size: 1.625rem !important;
    min-height: 84px;
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 768px) {
  .HeroCTA {
    font-size: 1rem !important;
    font-weight: 700;
    min-height: 64px;
    padding-right: 18px;
    padding-left: 18px;
  }
}
