.navbar-link:not(.is-arrowless)::after {
  border-color: #8a95a0 !important;
  margin-top: -7px;
}
@media screen and (max-width: 1023px) {
  .navbar-link:not(.is-arrowless)::after {
    border-color: transparent !important;
  }
}
@media screen and (max-width: 1023px) {
  .navbar-link:not(.is-arrowless):hover:after {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 1024px) {
  .navbar-link:not(.is-arrowless):hover:after {
    border-color: #0c92a0 !important;
  }
}

.navbar-menu is-active {
  background-color: transparent !important;
  //box-shadow: 0 14px 14px rgba(10, 10, 10, 0.1) !important;
}

@media screen and (max-width: 1023px) {
  .navShadow {
    box-shadow: 0 14px 14px rgba(10, 10, 10, 0.1) !important;
  }
}

.borderRightNav {
  border-right: 1px solid #9095a0;
  border-radius: 0px !important;
  height: 25px;
}

.hoverNav:hover {
  background-color: transparent !important;
  color: #0c92a0 !important;
}

@media screen and (max-width: 1023px) {
  .borderRightNav {
    height: 54px;
    padding-left: 28px;
    border-right: none;
  }
}
@media screen and (max-width: 1023px) {
  .guideNoBorder {
    height: 54px;
    padding-left: 28px;
  }
}
@media screen and (max-width: 1023px) {
  .accountHeaderNavItem {
    padding: 8px 12px 8px 28px !important;
    font-size: 16px !important;
    height: 44px;
    //color: #A0A8B0 !important;
    font-weight: 600;
  }
}

@media screen and (max-width: 1023px) {
  .accountNavItem {
    height: 54px;
    padding-left: 28px !important;
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1023px) {
  .navTop {
    background-color: #fff;
    position: sticky;
  }
}

.catFont {
  font-size: 16px !important;
  letter-spacing: 0.54px;
}

.catFont:hover {
  background-color: transparent !important;
  color: #0c92a0 !important;
}

.navbar-burger:hover {
  background-color: transparent !important;
}
