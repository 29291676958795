
.signIn {
    color: #202836;
    background-color: #F4F5F7;
    border: none;
    box-shadow: none;
    transition: all 0.30s ease-in-out;
    padding-left: 48px;
    margin-bottom: 8px;
}
.signIn::placeholder {
    color: #5F636C;

}
.signIn:focus {
    background-color: #FFF;
    box-shadow: 0px 0px 2px 2px #63B3ED ;
    opacity: 1;
}