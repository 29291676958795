@import "Auth.scss";
@import "AuthFooter.scss";
@import "AuthSection.scss";
@import "AuthSocial.scss";
@import "BackgroundImage.scss";
@import "CategoryTabs.scss";
@import "Clients.scss";
@import "ContactSection.scss";
@import "CTAButton.scss";
@import "DashboardSection.scss";
@import "FaqItem.scss";
@import "Features.scss";
@import "Features2.scss";
@import "Footer.scss";
@import "formField.scss";
@import "HeroSection.scss";
@import "LandingFeatures.scss";
@import "NavBar.scss";
@import "NewsletterSection.scss";
@import "PageLoader.scss";
@import "Pricing.scss";
@import "pricingSection.scss";
@import "RoadmapSection.scss";
@import "Section.scss";
@import "SectionHeader.scss";
@import "SettingsNav.scss";
@import "SettingsSection.scss";
@import "TeamBios.scss";
@import "Threesteps.scss";
@import "updatesStyle.scss";
@import "walloflove.scss";
@import "walloflove.scss";
@import "overview.scss";
@import "sideBar.scss";

.te-primary-button {
  color: #fff;
  height: 36px;
  border-radius: 7px;
  background: linear-gradient(
    163deg,
    rgba(var(--te-selected-grad-top), 1) 0%,
    rgba(var(--te-selected-grad-bottom), 1) 100%
  );
  box-shadow: 0px 4px 12px -6px rgb(92, 171, 255); //var(--te-selected-shadow);
  border: none;
  cursor: pointer;
  font-weight: 600;
  color: #fff;
  background-image: linear-gradient(180deg, #3c86fc 0%, #1f7af2 91%, #1466cd 100%);
  &:hover {
    filter: brightness(1.075);
  }
  &:active {
    filter: brightness(1.125);
  }
  &:disabled {
    filter: grayscale(1) brightness(0.925) opacity(0.55);
  }
}

.header-cta-button {
  font-size: 24px;
  margin: 0 auto;
  height: 72px;
  width: 382px;
  max-width: calc(100vw - 12px);
  box-shadow: 0 12px 24px -2px rgba(0, 60, 255, 0.3), 0 1px 3px -2px rgba(0, 35, 147, 0.5),
    0 18px 24px -12px rgba(0, 35, 147, 0.5), 0 22px 24px -12px rgba(7, 22, 74, 0.1),
    inset 0 -1px 3px 1px rgba(0, 0, 0, 0.2), inset 1px 1px 2px 0 rgba(255, 255, 255, 0.5);
  border-radius: 18px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.header-cta-button::after {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0px;
  left: -58px;
  width: 490px;
  height: 238px;
  opacity: 0.84;
  background-image: radial-gradient(
    circle at 50% 58%,
    rgba(1, 26, 211, 0.8) 0%,
    rgba(255, 255, 255, 0) 54%,
    rgba(255, 255, 255, 0) 54%
  );
}
