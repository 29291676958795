.AuthSocial {
  &__icon {
    img {
      width: 20px;
    }
  }

  &__tag {
    position: absolute;
    top: -8px;
    right: -8px;
    opacity: 0.85;
  }
}
