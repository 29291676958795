.NewsletterSection {
  &__form-container {
    width: 100%;
    max-width: 600px;
    // So alignment is controlled
    // by parent text alignment
    display: inline-block;
  }
}

.help.is-danger {
  color: #fff;
  font-size: 16px;
}

.h1news {
  color: #fff !important;
}

.field.is-grouped > .control:not(:last-child) {
  margin-right: 1rem;
}

.pt7 {
  padding-top: 7rem;
}

@media screen and (min-width: 768px) {
  .preFooterBgBox1 {
    top: 100px;
    left: -146px;
    height: 280px;
    width: 497px;
  }
  .preFooterBgBox2 {
    top: -39px;
    left: 20px;
    height: 420px;
    width: 494px;
  }
  .preFooterBgBox3 {
    top: -90px;
    left: 290px;
    height: 140px;
    width: 250px;
  }
  .preFooterBgBox4 {
    top: -33px;
    left: 255px;
    height: 279px;
    width: 293px;
  }
}

@media screen and (max-width: 768px) {
  .preFooterBgBox1 {
    top: 188px;
    left: -180px;
    height: 190px;
    width: 247px;
  }
  .preFooterBgBox2 {
    top: 144px;
    left: -70px;
    height: 180px;
    width: 160px;
  }
  .preFooterBgBox3 {
    top: 200px;
    left: 30px;
    height: 100px;
    width: 130px;
  }
  .preFooterBgBox4 {
    top: 157px;
    left: 55%;
    height: 199px;
    width: 143px;
  }
  .pt7 {
    padding-top: 3rem;
  }
}
