.AuthSection {
    &__container {
        max-width: 450px;
    }
}

.signInBox {
    padding: 24px;
}

@media screen and (min-width: 768px) {
    .signInBox {
        min-width: 480px;
        padding: 44px;
    }
}
