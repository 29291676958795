.circleWnumber {
  font-family: Rubik, sans-serif;
  position: absolute;
  border-radius: 100%;
  background-color: #363636;
  height: 28px;
  width: 28px;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  align-items: center;
  display: inline-block;
  text-align: center;
  padding-top: 2px;
}

.numberList {
  counter-reset: section;
  list-style: none;
  color: #565d65;
  font-size: 18px;
  font-family: Rubik, sans-serif;
  position: relative;
}

.listItem {
  padding: 0 0 32px 44px;
  margin: 0 0 12px 0;
  line-height: 20px;
  cursor: pointer;
  align-items: center;
  color: #646f7d;
}

.listItem:before {
  color: #fff;
  margin-right: 16px;
  content: counter(section);
  counter-increment: section;
  display: inline-block;
  height: 28px;
  width: 28px;
  font-weight: 500;
  font-size: 17px;
  color: #fff;
  background-color: #363636;
  border-radius: 100%;
  text-align: center;
  padding-top: 4px;
  position: absolute;
  left: 0;
}

.hovered {
  transform: scale(1.1);
}
