.featureh2 {
  font-size: 48px;
  line-height: 54px;
  font-weight: 900;
}
.landingFeatureContainer {
  padding-top: 8rem;
  padding-bottom: 8rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.landingFeatureContainerReverse {
  padding-top: 8rem;
  padding-bottom: 10rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.featureP {
  font-size: 1.375rem;
  color: #4a545e;
  font-family: Rubik, sans-serif;
}
.dropzoneBox {
  background-image: linear-gradient(141deg, #e8fdec 26%, #daf8ec 99%);
  border: 1px solid #46dd89;
  border-radius: 12px;
  left: 15%;
}
.spaceCard {
  z-index: 100;
  left: 35%;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 3px 10px -2px rgba(44, 48, 55, 0.18), 0 30px 84px -18px rgba(31, 37, 38, 0.36);
}
.spaceCardDark {
  z-index: 100;
  background: #1f2123;
  border-radius: 12px;
  box-shadow: 0 9px 36px -5px rgba(12, 12, 15, 0.8), 0 48px 66px -35px rgba(12, 12, 15, 0.5);
}
.PurpleCard {
  height: 260px;
  width: 300px;
  position: absolute;
  top: 100px;
  left: 40px;
  background: #ffe5b0;
  border-radius: 20px;
  z-index: 5;
}
.PinkCard {
  height: 180px;
  width: 200px;
  position: absolute;
  top: 50px;
  left: 190px;
  background: #7aeaac;
  z-index: 3;
  border-radius: 20px;
}

@media (max-width: 767px) {
  .landingFeatureContainer {
    padding-top: 2rem;
    flex-direction: column-reverse;
    display: flex;
  }
  .landingFeatureContainerReverse {
    padding-top: 0rem;
    padding-bottom: 10rem;
  }
  .dropzoneBox {
    left: 5%;
  }
  .spaceCard {
    left: 25%;
  }
  .hidddenOnTouch {
    opacity: 0;
  }
}
