.DashboardSection {
  &__paid-message {
    max-width: 300px;
  }

  &__image {
    margin: 0 auto;
    max-width: 370px;
  }
}

.SpaceGrid {
  align-items: start;
  display: grid;
  grid-gap: 24px;
  //grid-template-columns: repeat(auto-fill, minmax(276px, 1fr));
  grid-template-columns: repeat(auto-fill, minmax(374px, 1fr));
  justify-content: center;
}

.upgradeTitle {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.stickyCat {
  position: sticky;
  top: 23px;
  z-index: 100;
  max-width: 92.5%;
}

@media screen and (max-width: 1023px) {
  .stickyCat {
    position: sticky;
    top: 2px;
    z-index: 100;
  }
}
