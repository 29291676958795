.SectionHeader {
  // Add bottom margin if element below
  &:not(:last-child) {
    margin-bottom: 2.2rem;
  }

  &__no-classname {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    max-width: 700px;
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: inline-block;
  }
}

@media screen and (min-width: 600px) {
  .SectionheroLine {
    line-height: 71px;
    max-width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .SectionheroLine {
    max-width: 400px;
  }
}
