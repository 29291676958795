.HeroSection {
    &__image {
        margin: 0 auto;
        max-width: 570px;
    }
}

.heroLine {
    font-size: 94px !important;
    font-weight: 900 !important;
    letter-spacing: -0.6px;
}

@media screen and (min-width: 768px) {
    .heroBg {
        padding-top: 4rem !important;
        padding-bottom: 18rem !important;
    }
    .heroBgBox1 {
        top: -10px;
        left: 24%;
        height: 310px;
        width: 577px;
    }
    .heroBgBox2 {
        top: -96px;
        left: 55%;
        height: 309px;
        width: 350px;
    }
    .heroBgBox3 {
        top: -60px;
        left: 70%;
        height: 160px;
        width: 270px;
    }
    .heroBgBox4 {
        top: -23px;
        left: 63%;
        height: 299px;
        width: 313px;
    }
    .heroVideo {
        margin-top: -16rem;
    }
    .heroLine {
        line-height: 78px;
        max-width: 718px;
    }
    .subHeroLine {
        color: #51526d !important;
        font-size: 22px;
        line-height: 32px;
        max-width: 600px;
        text-align: center;
    }
    .hero2Line {
        line-height: 42px;
        max-width: 868px;
    }
    .contextItem {
        padding-left: 2.25rem;
    }
    .contextMain {
        padding-left: 4.5rem;
    }
    .deskMarginAuto {
        margin: auto;
    }
    .padding2242 {
        padding: 2rem 6rem 4rem 2rem;
    }
    .CTAsubtitle {
        font-size: 0.725rem;
    }
    .brandsRow1 {
        column-gap: 3.2rem;
    }
    .brandsRow2 {
        column-gap: 4.4rem;
    }
}

@media screen and (max-width: 768px) {
    .heroBg {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }
    .heroBgBox1 {
        top: -2px;
        left: 16%;
        height: 190px;
        width: 247px;
    }
    .heroBgBox2 {
        top: -56px;
        left: 43%;
        height: 180px;
        width: 160px;
    }
    .heroBgBox3 {
        top: -40px;
        left: 60%;
        height: 100px;
        width: 130px;
    }
    .heroBgBox4 {
        top: -29px;
        left: 54%;
        height: 199px;
        width: 143px;
    }
    .heroHeader {
        margin-top: 0rem;
    }
    .heroVideo {
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15),
            0 20px 50px -12px rgba(101, 111, 119, 0.35);
        margin-top: -6rem;
    }
    .heroLine {
        font-size: 3.6rem !important;
        line-height: 46px;
        max-width: 404px;
        font-weight: 900 !important;
    }
    .subHeroLine {
        color: #51526d !important;
        font-size: 16px;
        line-height: 20px;
        margin-top: -0.6rem;
        max-width: 440px;
        text-align: center;
    }

    .padding2242 {
        padding: 2rem 2rem 4rem 2rem;
    }
    .CTAsubtitle {
        font-size: 0.65rem;
    }
}
@media screen and (max-width: 768px) {
    .hero2Line {
        font-size: 36px !important;
        text-align: left;
        line-height: 42px;
        max-width: 440px;
    }
    .contextItem {
        padding-left: 3rem;
    }
    .contextMain {
        padding-left: 3rem;
    }
}

@media screen and (max-width: 600px) {
    .brandsRow1 {
        column-gap: 1.2rem;
    }
    .brandsRow2 {
        column-gap: 1.6rem;
    }
    .brands {
        margin-top: -2rem;
    }
    .heroLine {
        max-width: 404px;
    }
    .contextItem {
        padding-left: 3rem;
    }
    .contextMain {
        padding-left: 3rem;
    }
}

.hero2Line {
    font-size: 28px !important;
    font-weight: 800 !important;
    text-align: center;
}

.heroIframe {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border: 0;
    transform: scale(1.003);
}

:root {
    --star-size: 16px;
    --star-color: #fff;
    --star-background: #fc0;
    --rating: 2.3;
}

.Stars {
    margin-top: 1rem;
    --percent: calc(var(--rating) / 2.4925 * 100%);

    display: inline-block;
    font-size: var(--star-size);
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;

    &::before {
        content: '★★★★★';
        letter-spacing: 3px;
        background: linear-gradient(
            90deg,
            var(--star-background) var(--percent),
            var(--star-color) var(--percent)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
